export default function privacyPolicy() {
    document.title = "Privacy Policy - Best Meet Recorder Extension";

    return (
        <div className="relative isolate px-6 pt-14 lg:px-8">
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                />
            </div>
            <div className="w-[80%] h-max m-auto p-8">
                <h1 className="w-full text-center font-bold text-5xl text-btnDarker">Privacy Policy</h1>
                <div className="w-full mt-8 mb-10">
                    <h3 className="w-full font-semibold text-xl">
                        Is it safe to use the extension?
                    </h3>
                    <hr className="w-[40px] h-[3px] rounded mt-1 mb-1 bg-btnColor" />
                    <p className="w-full m-auto text-sm text-justify mt-2">
                        Chrome store extension codes are reviewed manually by Google team, if an extension is danger on user privacy, or goes against the terms of use, they would take it down immediately. Yes, It is safe.
                    </p>
                </div>
                <div className="w-full mt-8 mb-10">
                    <h3 className="w-full font-semibold text-xl">
                        Is my personal data exposed to any third parties?
                    </h3>
                    <hr className="w-[40px] h-[3px] rounded mt-1 mb-1 bg-btnColor" />
                    <p className="w-full m-auto text-sm text-justify mt-2">
                        <li className="mt-1 mb-1 w-full list-disc text-sm">
                            Not being sold to third parties
                        </li>
                        <li className="mt-1 mb-1 w-full list-disc text-sm">
                            Not being used or transferred for purposes that are unrelated to the item's core functionality
                        </li>
                        <li className="mt-1 mb-1 w-full list-disc text-sm">
                            Not being used or transferred to determine creditworthiness or for lending purposes
                        </li>
                    </p>
                </div>
                <div className="w-full mt-8 mb-10">
                    <h3 className="w-full font-semibold text-xl">
                        Are my conversations exposed to any third parties?
                    </h3>
                    <hr className="w-[40px] h-[3px] rounded mt-1 mb-1 bg-btnColor" />
                    <p className="w-full m-auto text-sm text-justify mt-2">
                        Your conversations and data are kept secure in accordance with Google Meet's privacy guidelines. As for data handling, it aligns with the specifications outlined previously.
                    </p>
                </div>
                <div className="w-full mt-8 mb-10">
                    <h3 className="w-full font-semibold text-xl">
                        What all data do we collect?
                    </h3>
                    <hr className="w-[40px] h-[3px] rounded mt-1 mb-1 bg-btnColor" />
                    <p className="w-full m-auto text-sm text-justify mt-2">
                        We just need minimal data for tracking purpose to resolve bugs if any on the extension and for basic analytics. We collect your email, number and the events performed on the extension. All this information is not sold to any third parties
                    </p>
                </div>
            </div>
        </div>
    );
}
