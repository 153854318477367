import React from "react";


export default function welcomeBanner(props) {

    return (
        <>
            <div className="w-full h-[240px] bg-gray-200 rounded flex items-center justify-center">
                <div className="w-max h-max">
                    <h1 className="text-center text-4xl font-medium text-bgColor p-2">Welcome, {props.name}</h1>
                    <p>This is where the meetings you record will be saved or shown</p>
                </div>
            </div>
        </>
    )
}
