import React from "react";
import MainLayout from "../../layouts/mainLayout";
// import Video from "../../assets/pexels-yunus-tuğ-20062451 (2160p).mp4";
// import Poster from "../../assets/mobbana-logo.png";
// import Data from "../../assets/Transcipt rbn-ijab-tuu 5-00-06 PM.json"
export default function VideoPage() {
  return (
    <MainLayout>
      <div className="!tracking-wider p-3 pl-7 h-[100vh] overflow-x-hidden overflow-y-scroll w-full max-w-[calc(100vw-6rem)] flex flex-col items-center ">

        <div className="w-full h-max flex justify-start">
          <h1 className="text-btnDarker p-2 m-2 text-2xl font-normal">
            Meetings - 11/02/2024
          </h1>
        </div>

        <div className="w-full h-max flex  items-center justify-between p-1 pl-4 m-2">
          <div className="w-[70%] h-max flex">
            <div className="pr-3 mr-3 flex items-center">
              <span className="text-md font-semibold">Owned By</span>
              <button className="w-9 h-9 border-none flex items-center justify-center bg-black outline-none rounded-full text-md text-white ml-2">
                US
              </button>
            </div>
          </div>
          <div className="w-[18%] h-max">
            <div
              style={{ boxShadow: "rgba(0, 8, 82, 0.1) 0px 5px 10px" }}
              className="w-[75%]  flex items-center rounded p-2 h-max"
            >
              <div className="mr-1 ml-1 cursor-pointer rounded p-2 hover:bg-gray-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                  />
                </svg>
              </div>
              <div className="mr-1 ml-1 cursor-pointer rounded p-2 hover:bg-gray-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
              </div>
              <div className="mr-1 ml-1 cursor-pointer rounded p-2 hover:bg-gray-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center items-start h-max mt-5 mb-2 ">

          <div style={{ boxShadow: "rgba(0, 8, 82, 0.1) 0px 5px 10px" }} className="w-[40%] bg-lightBg h-[640px] rounded-lg p-4 m-2">

            <div className="w-full h-[608px] flex flex-col items-center overflow-y-scroll">
              {
                // Data.map((obj, index) => {
                //   return (<div key={index} className="flex w-full h-max p-2 items-start">
                //     <div className="w-[23px] h-[23px] mr-1 mt-1 rounded-full bg-center bg-cover" style={{ backgroundImage: `url(${obj.image})` }}>
                //     </div>

                //     <div className="w-[90%] h-max flex flex-col items-start">
                //       <span className="w-full h-max text-base font-medium p-1">
                //         {obj.speaker}
                //       </span>
                //       <span className="w-full h-max p-1 text-xs">
                //         {obj.content}
                //       </span>
                //     </div>
                //   </div>)
                // })
              }
            </div>

          </div>

          <div style={{ boxShadow: "rgba(0, 8, 82, 0.1) 0px 5px 10px" }} className="w-[60%] rounded p-2 m-2 flex flex-col items-center">
            {/* <video
              src={Video}
              playsInline
              preload="metadata"
              poster={Poster}
              controlsList="nodownload"
              controls
              className="w-full h-[480px]"
            ></video> */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
