import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { NavLink, useNavigate } from "react-router-dom";
import Mobbana from "../../assets/mobbana-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";

export function SidebarWithSearch() {
  const [show, setShow] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const user = useSelector((data) => data.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle screen size change
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCollapse(true); // Collapse the sidebar on smaller screens
    } else {
      setCollapse(false); // Expand the sidebar on larger screens
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Card
      id="dashboard"
      onMouseEnter={() => window.innerWidth >= 768 && setShow(true)}
      onMouseLeave={() => window.innerWidth >= 768 && setShow(false)}
      className="h-[calc(100vh-0rem)] !font-sans w-[6rem] md:w-[16rem] p-4 shadow-xl bg-bgColor rounded-none !tracking-wider shadow-blue-gray-900/5"
      style={{ width: collapse ? "6rem" : "16rem" }}
    >
      <button
        onClick={() => setCollapse(!collapse)}
        className="border-none outline-none w-7 min-w-auto h-7 absolute top-16 drop-shadow-lg rounded bg-white flex justify-center items-center md:hidden"
        style={{
          left: `${collapse ? "5.2rem" : "15rem"}`,
          transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"}`,
          display: `${show ? "block" : "none"}`,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>

      <div className="mb-2 flex items-center gap-4 p-4">
        <img src={Mobbana} alt="brand" className="h-8 w-8" />
        <Typography
          variant="h5"
          className="text-white"
          style={{
            display: `${collapse ? "none" : "inline"}`,
            letterSpacing: "0.05rem",
          }}
        >
          Mobbana
        </Typography>
      </div>

      <List className="h-[80%] md:min-w-[240px] min-w-[auto]">
        <hr className="my-2 border-white-50" />
        <NavLink to="/recorded-meetings">
          <ListItem className="text-white">
            <ListItemPrefix className={collapse ? "mr-0" : "mr-4"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            </ListItemPrefix>
            <span style={{ display: `${collapse ? "none" : "inline"}` }}>
              Recorded Meetings
            </span>
          </ListItem>
        </NavLink>

        <NavLink to="/feature-request">
          <ListItem className="text-white">
            <ListItemPrefix className={collapse ? "mr-0" : "mr-4"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                />
              </svg>
            </ListItemPrefix>
            <span style={{ display: `${collapse ? "none" : "inline"}` }}>
              Feature Request
            </span>
          </ListItem>
        </NavLink>
      </List>

      <div
        className="flex items-center p-4 w-full max-w-[200px] align-bottom relative"
        onMouseEnter={() => setShowLogout(true)}
        onMouseLeave={() => setShowLogout(false)}
      >
        <div
          className="w-8 h-8 text-sm rounded-full bg-blue-gray-900 bg-center bg-cover text-white flex justify-center items-center"
          style={{ backgroundImage: `url(${user.data.picture})` }}
        ></div>
        <span
          className="text-white ml-2"
          style={{ display: `${collapse ? "none" : "inline"}` }}
        >
          {user.data.given_name + " " + user.data.family_name}
        </span>
        {showLogout && (
          <button
            onClick={handleLogout}
            className="absolute bottom-[-40px] w-full mb-4 text-black bg-white px-2 py-1 rounded"
          >
            Logout
          </button>
        )}
      </div>
    </Card>
  );
}
