import React from "react";
import howToUse from "../../assets/howtouse.svg";
export default function HowToUseSection() {
  return (
    <div className="bg-white py-24 sm:py-20">
      <div className="mx-auto max-w-[90%] px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-btnDarker uppercase">
            Step-by-Step Guide
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How to Use
          </p>
        </div>
        <div className="w-[90%] mt-6 m-auto">
          <div className="flex items-center w-full h-max flex-col-reverse md:flex-row">
            <div className="w-full md:w-[40%] p-4">
              <div className="flex mt-3">
                <div className="w-[20%]  flex justify-end items-start">
                  <span className="text-btnDarker text-3xl font-extrabold">
                    01
                  </span>
                </div>
                <div className="w-[80%] pl-4">
                  <div>
                    <h3 className="font-semibold text-lg pb-1">
                      Start Recording
                    </h3>
                  </div>
                  <div>
                    <h5 className="text-sm pt-1 pb-1 text-justify">
                      Press the "Start Recording" button. It may take a few
                      seconds (approximately 10-30 seconds) for bot to join the
                      meeting.
                    </h5>
                  </div>
                </div>
              </div>
              <hr className="w-[80%] h-[3px] rounded bg-gray-300 m-auto mt-4" />
              <div className="flex mt-3">
                <div className="w-[20%]  flex justify-end items-start">
                  <span className="text-btnDarker text-3xl font-extrabold">
                    02
                  </span>
                </div>
                <div className="w-[80%] pl-4">
                  <div>
                    <h3 className="font-semibold text-lg pb-1">
                      Allow the Bot to Join the Meeting
                    </h3>
                  </div>
                  <div>
                    <h5 className="text-sm pt-1 pb-1 text-justify">
                      Once you press the start button, please allow the bot to
                      join the meeting. The bot will then start recording and
                      transcribing the meeting for you. You can relax knowing
                      that everything is being taken care of by our bot.
                    </h5>
                  </div>
                </div>
              </div>
              <hr className="w-[80%] h-[3px] rounded bg-gray-300 m-auto mt-4" />
              <div className="flex mt-3">
                <div className="w-[20%]  flex justify-end items-start">
                  <span className="text-btnDarker text-3xl font-extrabold">
                    03
                  </span>
                </div>
                <div className="w-[80%] pl-4">
                  <div>
                    <h3 className="font-semibold text-lg pb-1">
                      Stop Recording
                    </h3>
                  </div>
                  <div>
                    <h5 className="text-sm pt-1 pb-1 text-justify">
                      When you're done with the meeting, press the "Stop
                      Recording" button to save the recording. The recording
                      will be saved on the Mobbana dashboard site, where you can
                      view and manage each recording along with its transcript.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[60%] flex items-center justify-center">
              <img src={howToUse} className="w-[70%] h-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
