import React from "react";
import { SidebarWithSearch } from "../../components/sidebar";

const MainLayout = ({ children }) => {
  return (
    <div className="w-full h-[100vh] flex items-center">
      <SidebarWithSearch />
      {children}
    </div>
  );
};

export default MainLayout;
