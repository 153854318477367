import React, { useEffect, useState } from "react";
import Video from "../../components/videoElement";
import Banner from "../../components/welcomBanner";
import Folder from "../../components/folderSection";
import MainLayout from "../../layouts/mainLayout";
import { getMeetings } from "../../services/api";
import { useSelector } from "react-redux";

export default function RecordedMeetingsPage() {
  document.title = "Recorded Meetings - Mobbana";
  const [toggle, setToogle] = useState("allClose");
  const [content, setContent] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [folders, setFolders] = useState([]);
  const [active, setActive] = useState("a");
  const user = useSelector((data) => data.user);

  const activeClass =
    "w-max h-max border-none outline-none rounded bg-btnColor hover:bg-btnDarker text-md text-white p-2 pl-3 pr-3 mr-3 flex items-center justify-between";
  const notActiveClass =
    "w-max h-max border-none outline-none rounded bg-btnNotActive hover:bg-btnNotActiveDarker text-md text-gray-800 p-2 pl-3 pr-3 mr-3 flex items-center justify-between";

  // for filters
  const openFilter = (str) => {
    if (toggle === str) setToogle("allClose");
    else setToogle(str);
  };

  const setFilter = (str) => {
    document.querySelector("#dateFilter").innerHTML = str;
  };

  const privateMeetings = () => {
    setActive("p");
    let privateMeeting = [];
    meetings.forEach((meeting) => {
      if (meeting.isPrivate) privateMeeting.push(meeting);
    });
    setContent(
      meetings.length ? (
        formatingMeetings(privateMeeting)
      ) : (
        <Banner name={user.data.given_name} />
      )
    );
  };

  // for search operation
  const search = (str) => {
    if (str !== "") {
      var parent = document.getElementById("contentHolder");
      var childNodes = parent.children;
      if (!childNodes[0].innerText.includes("Welcome")) {
        for (var i = 0; i < childNodes.length; i++) {
          if (childNodes[i].innerText.includes(str)) {
            childNodes[i].style.display = "block";
          } else {
            childNodes[i].style.display = "none";
          }
        }
      }
    } else {
      setContent(
        meetings.length ? (
          formatingMeetings(meetings)
        ) : (
          <Banner name={user.data.given_name} />
        )
      );
    }
  };

  const folder = () => {
    setActive("f");
    setContent(
      folders.length ? (
        formatingFolders()
      ) : (
        <Banner name={user.data.given_name} />
      )
    );
  };

  // to format any meeting in a desire way
  const formatingMeetings = (meetings) => {
    return meetings.map((meeting) => {
      return <Video id={meeting.id} />;
    });
  };

  const formatingFolders = () => {
    return folders.map((folder) => {
      return (
        <Folder
          folderName={folder.folderName}
          meetingCount={folder.meetingCount}
        />
      );
    });
  };

  const allMeetings = () => {
    setActive("a");
    setContent(
      meetings.length ? (
        formatingMeetings(meetings)
      ) : (
        <Banner name={user.data.given_name} />
      )
    );
  };

  // to extract folders and data
  const extractData = () => {
    meetings.forEach((meeting) => {
      if (meeting.folderName !== "") {
        folders.forEach((folder) => {
          if (folder.folderName === meeting.folderName) {
            setFolders((prevState) => {
              return [
                ...prevState,
                {
                  folderName: meeting.folderName,
                  meetingCount: folder.meetingCount++,
                },
              ];
            });
          } else {
            setFolders((prevState) => {
              return [
                ...prevState,
                {
                  folderName: meeting.folderName,
                  meetingCount: 1,
                },
              ];
            });
          }
        });
      }
    });
  };

  // first point where getting all the meetings
  const getMeeting = async () => {
    const response = await getMeetings({
      userId: user.userId,
      _token: user.token,
    });

    if (response.message === "Success.") {
      setMeetings(response.data);
      extractData();
    }
    setContent(
      meetings.length ? (
        formatingMeetings(meetings)
      ) : (
        <Banner name={user.data.given_name} />
      )
    );
  };

  useEffect(() => {
    // for production uncomment below
    // getMeeting();
    //for testing purpose uncomment below if you run only frontend
    // setContent(
    //   meetings.length ? (
    //     formatingMeetings(meetings)
    //   ) : (
    //     <Banner name={user.data.given_name} />
    //   )
    // );
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <div className="p-3 !tracking-wider pl-7 h-[100vh] w-full max-w-[calc(100vw-6rem)] flex flex-col items-center ">
        <div className="w-full h-max flex justify-start">
          <h1 className="text-btnDarker p-2 m-2 text-2xl font-semibold">
            Meetings
          </h1>
        </div>

        <div className="w-full h-max flex items-center justify-between p-1 pl-4 m-2 flex-wrap gap-[7px]">
          <div className="md:w-[50%] w-full  h-max flex flex-wrap gap-[7px]">
            <button
              className={active === "a" ? activeClass : notActiveClass}
              onClick={allMeetings}
            >
              All Meetings
            </button>
            <button
              className={active === "p" ? activeClass : notActiveClass}
              onClick={privateMeetings}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
              Private
            </button>
            <button
              className={active === "f" ? activeClass : notActiveClass}
              onClick={folder}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                />
              </svg>
              Folders
            </button>
          </div>

          <div className="md:w-[40%] w-full h-max flex items-center justify-between">
            <div className="w-[60%] relative h-max">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 absolute top-3 left-2 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              <input
                type="text"
                placeholder="Find your meetings"
                className="w-full border rounded border-solid border-blue-gray-400 h-max p-2 text-black pl-9 "
                onChange={(event) => search(event.target.value)}
              />
            </div>
            {active === "f" && (
              <div className="ml-2 h-full">
                <button className="flex items-center justify-between p-2 border-solid border-blue-gray-400 border rounded text-sm pr-3 pl-3 hover:bg-gray-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                    />
                  </svg>
                  Add Folder
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full h-max pl-5  flex items-center">
          <div
            className="cursor-pointer"
            onClick={() => openFilter("openFirst")}
          >
            <div className="w-max h-max flex items-center">
              <span className="text-sm mr-1" id="dateFilter">
                Latest
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            <div
              className="p-1 pt-3 pb-3 absolute z-10 bg-gray-200 rounded-md w-40 h-max"
              style={{
                display: `${toggle === "openFirst" ? "block" : "none"}`,
              }}
            >
              <li
                className="p-1 text-sm hover:bg-gray-400 rounded list-none"
                onClick={() => setFilter("Latest")}
              >
                Latest
              </li>
              <li
                className="p-1 text-sm hover:bg-gray-400 rounded list-none"
                onClick={() => setFilter("Past hour")}
              >
                Past hour
              </li>
              <li
                className="p-1 text-sm hover:bg-gray-400 rounded list-none"
                onClick={() => setFilter("Past day")}
              >
                Past day
              </li>
              <li
                className="p-1 text-sm hover:bg-gray-400 rounded  list-none"
                onClick={() => setFilter("Past week")}
              >
                Past week
              </li>
              <li
                className="p-1 text-sm hover:bg-gray-400 rounded  list-none"
                onClick={() => setFilter("Past month")}
              >
                Past month
              </li>
            </div>
          </div>
        </div>

        <div
          id="contentHolder"
          className="w-full h-max p-4 flex flex-wrap items-center"
        >
          {content}
        </div>
      </div>
    </MainLayout>
  );
}
