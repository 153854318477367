import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { signUpUser } from "../../services/api";
import meetImg from "../../assets/Remote team-rafiki.svg";
import Footer from "../../components/footer";

const SignupPage = () => {
  document.title = "Sign up - Mobbana";
  const token = useSelector((data) => data.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(token);
    if (token) {
      navigate("/recorded-meetings");
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  const handleSuccess = async (credentialResponse) => {
    // Decode the token to extract user information
    const decoded = jwtDecode(credentialResponse.credential);

    const response = await signUpUser(decoded);

    if (response.data.status) {
      console.log(response);
      dispatch(
        signup(
          response.data.data.token,
          response.data.data._id,
          response.data.data
        )
      );
      navigate("/recorded-meetings");
    }
  };

  const handleError = (error) => {
    console.log(error);
    console.log("Login Failed");
  };

  return (
    <>
      <div
        className="flex w-full justify-center md:h-screen h-full items-center flex-col md:flex-row p-10"
        style={{
          background:
            "linear-gradient(336deg, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 50%)",
        }}
      >
        <div className="md:w-[45%] p-10 flex items-center justify-center w-[80%] ">
          <div className="flex flex-col">
            <h1 className="text-6x1 font-medium p-2">
              Welcome to <span className="text-bgColor">Mobbana</span>
            </h1>
            <p className="pr-2 text-base pl-4">
              Elevate your Google Meet experience with seamless meeting
              recording capabilities. Never miss a detail, effortlessly capture
              every moment.
            </p>
            <div className="w-full h-max flex items-center justify-center">
              <img src={meetImg} className="w-[60%] h-auto" alt="" />
            </div>
          </div>
        </div>

        <div
          className="w-[80%] md:w-[30%] h-max p-10 flex items-center justify-center rounded-xl"
          style={{ boxShadow: "rgb(110 110 110 / 65%) 0px 8px 20px" }}
        >
          <div>
            <h2 className="font-medium text-2xl mb-4 text-center">
              <span className="text-lightBlue">Sign in</span> with <br />
              your professinal email
            </h2>
            <p className="mt-1 mb-1 p-1 text-sm text-center">
              Start recording your Google Meets effortlessly capture every
              moment!
            </p>
            <p className="mt-1 mb-8 p-1 text-sm text-center">
              Get started with the email that you will use to record your
              meetings
            </p>

            <div className="mt-2 mb-4 flex flex-col items-center justify-center">
              <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
            </div>
            <p className="mt-8 text-xs mb-4 text-center">
              By using mobbana you agree to the
              <br />
              Terms of Service and Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
