import React from "react";
import { NavLink } from "react-router-dom";

export default function VideoElement(props) {
  const [toggle, setToogle] = React.useState(false);
  const openMenu = () => setToogle(!toggle);
  const generateDynamicLink = () => {
    return `/recorded-meetings/${props.id}`;
  };
  return (
    <div
      style={{ boxShadow: "rgba(0, 8, 82, 0.1) 0px 5px 10px" }}
      className="w-60 h-40 rounded-2xl relative cursor-pointer flex flex-col justify-between bg-gray-500 p-1 m-1 mr-3 ml-3"
    >
      <div className=" w-full h-[25%] flex justify-between p-1">
        <div className="w-8 h-8 p-1 rounded-full bg-white flex justify-center items-center">
          <span className="text-black text-sm">US</span>
        </div>
        <div
          className="w-7 h-7 p-1 rounded-lg bg-white flex justify-center items-center"
          onClick={openMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
            />
          </svg>
        </div>
      </div>
      <NavLink to={generateDynamicLink()}>
        <div className="w-full bg-white p-2 absolute bottom-0 rounded-tl-none flex flex-col rounded-tr-none rounded-br-xl rounded-bl-xl left-0">
          <span className="text-sm text-black p-1 meetName">11/09/2024-meeting</span>
          <span className="text-sm text-black p-1">1:00 min</span>
        </div>
      </NavLink>
      <div
        className="w-30 z-10 h-30 p-1 rounded absolute -right-36 top-2 bg-gray-200"
        style={{ display: `${toggle ? "block" : "none"}` }}
      >
        <div className="text-sm p-3 flex items-end justify-between hover:bg-gray-500 rounded w-full h-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
            />
          </svg>
          <span>Add to folder</span>
        </div>
        <div className="text-sm p-3  hover:bg-gray-500 rounded flex items-end w-full h-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
            />
          </svg>
          <span>Download</span>
        </div>
        <div className="text-sm p-3 hover:bg-gray-500 rounded flex items-end w-full h-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
          <span>Delete</span>
        </div>
      </div>
    </div>
  );
}
