import React from "react";
import FolderImg from "../../assets/folder.png";

export default function folderSection(props) {

    return (
        <>
            <div className="m-2 w-[200px] h-auto p-4 cursor-pointer hover:bg-gray-100 rounded-md">
                <img src={FolderImg} alt="folder_icon" className="w-[90%] h-auto m-auto" />
                <h5 className="ml-2 capitalize font-medium p-2">
                    {props.folderName}
                </h5>
                <h6 className="ml-2 capitalize pl-2 text-sm">
                    <span>{props.meetingCount} meetings</span>
                </h6>
            </div>
        </>
    )
}
