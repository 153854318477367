export default function Footer() {
  return (
    <footer class="bg-bgColor py-8">
      <div class="container mx-auto px-6">
        <div class="md:flex md:items-center md:justify-between">
          <div class="mb-4 md:mb-0">
            <h3 class="text-white text-sm">
              © 2020 Mobbana, Inc. All rights reserved.
            </h3>
          </div>
          <div>
            <ul class="flex items-center justify-center md:justify-end">
              <li class="mr-6">
                <a href="#" class="text-white hover:text-gray-400">
                  About Us
                </a>
              </li>
              <li class="mr-6">
                <a href="#" class="text-white hover:text-gray-400">
                  Services
                </a>
              </li>
              <li class="mr-6">
                <a href="#" class="text-white hover:text-gray-400">
                  Contact
                </a>
              </li>
              <li>
                <a href="#" class="text-white hover:text-gray-400">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
