import { Card, CardBody, Typography } from "@material-tailwind/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import Recording from "../../assets/recorder.png";
import Transcript from "../../assets/transcript.png";
import Timer from "../../assets/timer.png";
import DND from "../../assets/dnd.png";
import FullScreen from "../../assets/full-screen.png";
import PIP from "../../assets/pip-mode.png";
import Remove from "../../assets/remove-participants.png";
import MuteAll from "../../assets/auto-mute.png";
import Join from "../../assets/auto-join.png";
import Deny from "../../assets/deny-participants.png";
import Admit from "../../assets/auto-admit.png";
import Bg from "../../assets/colour-picker.png";
import Screenshot from "../../assets/screenshot.png";
import Play from "../../assets/auto-record.png";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";

const features = [
  {
    name: "Recording Meeting",
    description:
      "Automatically record Google Meet sessions with a single click, ensuring you never miss important discussions.",
    icon: Recording,
  },
  {
    name: "Transcripting Meeting",
    description:
      "Convert spoken content into text, making it easy to review meeting details and share textual records.",
    icon: Transcript,
  },
  {
    name: "Auto-Record Meetings",
    description:
      "Begin recording automatically as soon as you enter a meeting, ensuring every moment is captured from the start.",
    icon: Play,
  },
  {
    name: "Auto Join Any Meetings",
    description:
      "Automatically join Google meet session when joining link provided to you.",
    icon: Join,
  },
  {
    name: "One-Click Participant Removal",
    description:
      "Quickly clear all participants from a meeting with just one click, ideal for managing privacy and security.",
    icon: Remove,
  },
  {
    name: "One-Click Mute All",
    description:
      "Instantly mute all participants to eliminate background noise and manage discussions efficiently.",
    icon: MuteAll,
  },
  {
    name: "Auto-Deny Entries",
    description:
      "Automatically deny entry to new participants, helping to maintain control over meeting attendance.",
    icon: Deny,
  },
  {
    name: "Auto-Admit Entries",
    description:
      "Seamlessly admit participants into meetings without manual approval, streamlining the entry process.",
    icon: Admit,
  },
  {
    name: "Do Not Disturb Mode",
    description:
      "Suppress notifications and alerts during a meeting to maintain focus and minimize disruptions.",
    icon: DND,
  },
  {
    name: "Full Screen Mode",
    description:
      "Expand the meeting view to full screen for a more immersive and focused meeting experience.",
    icon: FullScreen,
  },
  {
    name: "Picture in Picture Mode",
    description:
      "Enable a small, floating window for your meeting, allowing you to multitask without missing the action.",
    icon: PIP,
  },
  {
    name: "Custom Backgrounds",
    description:
      "Customize your virtual background during meetings for a more personalized or professional appearance.",
    icon: Bg,
  },
  {
    name: "Meeting Timer",
    description:
      "Automatically track the duration of your Google Meet sessions, providing precise insights into meeting lengths.",
    icon: Timer,
  },
  {
    name: "Screenshot of Your Google Meet",
    description:
      "Quickly capture and save screenshots of your meetings, perfect for record-keeping or sharing specific moments.",
    icon: Screenshot,
  },
];

export default function FeatureSection() {
  const swiperRef = useRef(null);
  const makeContent = () => {
    const slides = [];
    for (let index = 0; index < features.length; index++) {
      slides.push(
        <SwiperSlide className="!w-96" key={index}>
          <div className="flex flex-col items-center justify-center h-max w-96">
            <Card className="mt-6 w-96">
              <CardBody className="flex flex-col items-center justify-center">
                <img
                  src={features[index].icon}
                  className="h-auto w-12 mb-2"
                  alt=""
                />
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {features[index].name}
                </Typography>
                <Typography className="text-center">
                  {features[index].description}
                </Typography>
              </CardBody>
            </Card>
            <Card className="mt-6 w-96">
              <CardBody className="flex flex-col items-center justify-center">
                <img
                  src={features[index + 1].icon}
                  className="h-auto w-12 mb-2"
                  alt=""
                />
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {features[index + 1].name}
                </Typography>
                <Typography className="text-center">
                  {features[index + 1].description}
                </Typography>
              </CardBody>
            </Card>
          </div>
        </SwiperSlide>
      );
      index++;
    }
    return slides;
  };
  return (
    <div className="bg-white py-20 sm:py-32">
      <div className="mx-auto max-w-[90%] px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-btnDarker uppercase">
            what sets us apart
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Features
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience seamless interactions with Mobbana —offering all the
            advantages to simplify your communications, no extra steps required!
          </p>
        </div>
        <div className="flex items-center h-max">
          <div
            className="flex items-center justify-center bg-btnDarker rounded-[50%] cursor-pointer p-1 mr-2"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={45}
            ref={swiperRef}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper h-max w-max"
          >
            {makeContent()}
          </Swiper>
          <div
            className="flex items-center justify-center bg-btnDarker rounded-[50%] cursor-pointer p-1 ml-2"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
