import axios from "axios";


const api = axios.create({
  baseURL: "http://localhost:5001/app/",
});


export const signUpUser = (bodyData) => {
  return api.post("user/signUp", bodyData);
};

export const getMeetings = (bodyData) => {

  const authToken = bodyData._token;

  const headers = {
    'authorization': `Bearer ${authToken}`
  };

  return api.get("user/getAllMeetingsOfUser", {
    headers: headers,
    params: {
      userId: bodyData.userId
    }
  }).then(response => {
    return response.data;
  }).catch(error => {
    console.error('Error fetching meetings:', error);
    throw error;
  });
};

