import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import React from "react";
import StartBtn from "../../assets/start_btn.png";
import StopBtn from "../../assets/stopBtn.png";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

const Icon = ({ id, open }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
};

const FAQs = [
  {
    key: 3,
    que: "How can I allow the bot to join the meeting?",
    ans: "After starting the recording, grant permission for the bot to enter the meeting. Once approved, the bot will commence recording and transcribing the session.",
  },
  {
    key: 4,
    que: "What does the timer display: the duration of the meeting or the recording?",
    ans: "The timer displays the duration of the meeting, indicating the time spent actively participating in the meeting session.",
  },
  {
    key: 5,
    que: "What is the Control Center?",
    ans: "The Control Center refers to the settings panel within our Google Meet extension. It allows you to customize various aspects of your meeting experience.",
  },
  {
    key: 6,
    que: "What options are available in the Control Center?",
    ans: "The Control Center offers features such as selecting transcript language, choosing video quality, changing background color, taking screenshots, enabling automatic recording, auto-joining meetings, managing participant views, and controlling participant approvals.",
  },
  {
    key: 7,
    que: "Is the extension compatible with all Google Meet features?",
    ans: "Our extension is designed to integrate seamlessly with Google Meet, offering enhanced functionality while maintaining compatibility with standard features.",
  },
  {
    key: 8,
    que: "Can I customize the extension to suit my specific needs?",
    ans: "While our extension provides a range of customizable options, we're continuously improving and adding new features based on user feedback. We encourage you to share your suggestions with us on Request a feature",
  },
  {
    key: 9,
    que: "Is my data secure while using the extension?",
    ans: "Yes, we take your privacy seriously. Our extension adheres to strict security protocols to ensure your data remains protected.",
  },
  {
    key: 10,
    que: "How is my recorded meeting data stored and managed?",
    ans: "Recorded meeting data is securely stored on AWS. We employ encryption and other security measures to safeguard your recordings and transcripts.",
  },
  {
    key: 11,
    que: "Are my recordings and transcripts accessible to anyone else?",
    ans: "Your recordings and transcripts are strictly private and accessible only to you. We do not share your data with any third parties without your consent.",
  },
];

export default function FaqSection() {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  return (
    <div className="bg-white py-20 sm:py-32">
      <div className="mx-auto max-w-[90%] px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-btnDarker uppercase">
            Answers at Your Fingertips
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            FAQs
          </p>
        </div>
        <div className="w-[80%] m-auto mt-4 sm:w-full">
          <Accordion
            open={open === 1}
            icon={<Icon id={1} open={open} />}
            animate={CUSTOM_ANIMATION}
          >
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="text-md md:text-xl"
            >
              How do I start recording a meeting?
            </AccordionHeader>
            <AccordionBody className="text-sm md:text-md">
              To initiate recording, press the "Start Recording"{" "}
              <img src={StartBtn} className="h-auto w-24 inline-block" alt="" />{" "}
              button within the extension. Please allow a few seconds for the
              bot to join the meeting.
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open === 2}
            icon={<Icon id={2} open={open} />}
            animate={CUSTOM_ANIMATION}
          >
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="text-md md:text-xl"
            >
              How do I stop recording a meeting?
            </AccordionHeader>
            <AccordionBody className="text-sm md:text-md">
              When your meeting concludes, click the "Stop Recording"{" "}
              <img src={StopBtn} className="h-auto w-24 inline-block" alt="" />{" "}
              button. This action saves the recording, which can be accessed and
              managed through the Mobbana dashboard site
            </AccordionBody>
          </Accordion>
          {FAQs.map((faq) => {
            return (
              <Accordion
                key={faq.key}
                open={open === faq.key}
                icon={<Icon id={faq.key} open={open} />}
                animate={CUSTOM_ANIMATION}
              >
                <AccordionHeader
                  onClick={() => handleOpen(faq.key)}
                  className="text-md md:text-xl"
                >
                  {faq.que}
                </AccordionHeader>
                <AccordionBody className="text-sm md:text-md">
                  {faq.ans}
                </AccordionBody>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}
