import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import FeatureSection from "../../components/featureSection";
import HowToUseSection from "../../components/howToUseSection";
import FaqSection from "../../components/faqSection";
import Footer from "../../components/footer";
import Mobbana from "../../assets/mobbana-logo.png";
import { Link as PageLink, Element } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

const navigation = [
  { name: "How To Use", href: "howToUse" },
  { name: "Features", href: "features" },
  { name: "FAQs", href: "faq" },
];

export default function HeroSection() {
  document.title = "Mobbana - Best Meet Recorder Extension";
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <RouterLink to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Mobbana</span>
                <img className="h-8 w-auto" src={Mobbana} alt="" />
              </RouterLink>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <PageLink
                  key={item.name}
                  to={item.href}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                >
                  {item.name}
                </PageLink>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              {/* <RouterLink
                // to="/signUp"
                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Sign Up
              </RouterLink> */}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <RouterLink to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Mobbana</span>
                  <img className="h-8 w-auto" src={Mobbana} alt="" />
                </RouterLink>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <PageLink
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </PageLink>
                    ))}
                  </div>
                  <div className="py-6">
                    <RouterLink
                      to="/signUp"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </RouterLink>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>

        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto py-32 sm:py-30 lg:py-40 max-w-[50rem]">
            <div className="text-center">
              <h1 className="font-bold tracking-tight text-gray-900 text-3xl md:text-5xl leading-snug">
                From Insightful Discussions to Inspiring Ideas, <br /> Record It
                All with <span className="text-btnDarker">Mobbana</span>
              </h1>
              <p className="mt-6 text-sm md:text-lg leading-8 text-gray-600">
                Record meetings for free with our Google Meet Chrome Extension.
                Capture calls in high quality video and sound, and access them
                immediately afterwards in your meetings library. Our meeting
                recorder makes it easier than ever to share call moments.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <PageLink
                  to="#"
                  className="rounded-md bg-btnColor px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-btnDarker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Free Download
                </PageLink>
                <PageLink
                  to="#"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </PageLink>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div>
      <Element name="howToUse">
        <HowToUseSection />
      </Element>
      <Element name="features">
        <FeatureSection />
      </Element>
      <Element name="faq">
        <FaqSection />
      </Element>
      <Footer />
    </>
  );
}
