import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HeroSection from "../../view/heroSection";
import SignupPage from "../../view/signup";
import { useSelector } from "react-redux";
import VideoPage from "../../view/video";
import RecordedMeetingsPage from "../../view/recordedMeetings";
import TermsCondition from "../../view/termsCondition";
import PrivacyPolicy from "../../view/privacyPolicy";
import FeatureRequest from "../../view/featureRequest";

function Router() {
  const routes = [
    { path: "/", element: <HeroSection /> },
    { path: "/signUp", element: <SignupPage /> },
    { path: "/terms-of-service", element: <TermsCondition /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/recorded-meetings/:id", element: <VideoPage /> },
    {
      path: "/recorded-meetings",
      element: <RecordedMeetingsPage />,
    },
    { path: "/feature-request", element: <FeatureRequest /> },
  ];

  function PrivateRoute({ element, private: isPrivate }) {
    let token = null;
    useSelector((data) => {
      token = data.user.token;
    });

    const isAuthenticated = token;

    if (isPrivate && !isAuthenticated) {
      return <Navigate to="/signUp" />;
    }

    return element;
  }

  return (
    <div className="App">
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PrivateRoute element={route.element} private={route.private} />
            }
          />
        ))}
      </Routes>
    </div>
  );
}

export default Router;
