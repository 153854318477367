// actions/userActions.js
export const signup = (token, user, data) => {
  return {
    type: "SIGNUP",
    token,
    user,
    data,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};
