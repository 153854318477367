// store.js
import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // This will use local storage

import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  user: userReducer,
  // Add more reducers if needed
});

const persistConfig = {
  key: "root1",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export { store, persistor };
