import React from "react";
import MainLayout from "../../layouts/mainLayout";
import {
  Card,
  Input,
  Textarea,
  Button,
  Typography,
} from "@material-tailwind/react";
import feature from "../../assets/Preferences-pana.svg";

export default function FeatureRequest() {
  document.title = "Feature Request - Mobbana";
  return (
    <MainLayout>
      <div className="p-3 !tracking-wider pl-7 h-[100vh] w-full max-w-[calc(100vw-6rem)] flex flex-col">
        <div className="w-full h-max flex justify-start">
          <h1 className="text-btnDarker p-2 m-2 text-2xl font-semibold">
            Feedback
          </h1>
        </div>
        <div className="p-2 m-2 w-full max-w-[calc(100vw-6rem)] flex items-center">
          <div className="w-[50%] h-auto p-3">
            <img src={feature} className="w-[85%] h-auto" alt="" />
          </div>
          <Card
            className="w-[50%] h-auto m-2"
            color="transparent"
            shadow={false}
          >
            <h3 className="text-xl text-lightBlue font-semibold">
              Make a request to serve you better
            </h3>
            <Typography color="gray" className="mt-1 font-normal">
              Our Feature Request Platform for Enhanced Service Excellence
            </Typography>
            <form className="mt-8 mb-2 w-full max-w-screen-lg sm:w-96">
              <div className="mb-1 flex flex-col gap-6">
                <Input variant="outlined" label="Name" placeholder="Ex. John" />
                <Input
                  variant="outlined"
                  label="Email"
                  placeholder="Ex. name@mail.com"
                />
                <Textarea variant="outlined" label="Feature" />
                <Button variant="outlined">Request a Feature</Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
}
